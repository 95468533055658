body {
    padding-top: 56px;
}

.loading-container {
    padding: 28px;
    margin-top: 150px;
}

.spinner-custom {
    width: 3rem;
    height: 3rem;
}

.invite-jumbo {
    padding-bottom: 1rem !important;
}

.invite-form-row {
    padding: 5px 0 5px 0;
}

.invite-form-header-spacer {
}

.invite-form-header {
    display: flex;
    align-items: flex-end;
    text-indent: 0.2rem;
    font-size: larger;
    font-weight: 550;
}

.invite-submit-row {
    margin-top: 2rem;
    margin-bottom: 0;
}

.pw-box {
    padding-bottom: 6px;
}

.mfa-box {
    display: flex;
    align-items: flex-end;
    flex-grow: 0.2;
    font-size: larger;
    font-weight: 550;
}

.mfa-box-text {
    padding-left: 3px;
}